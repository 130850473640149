
import { Component, Vue } from "vue-property-decorator"

@Component
export default class PageNotFound extends Vue {
  handleLogin() {
    this.$router.push({ name: this.$routes.Home })
  }

  navigateToHome() {
    this.$router.push({ name: this.$routes.Home })
  }
}
